import * as React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout/Layout'
import Appointments from '../components/Appointments/Appointments'
import Team from '../components/Team/Team'
import Text from '../components/Text/Text'
import ThreeCard from '../components/ThreeCard/ThreeCard'
import Underline from '../components/Underline/Underline'
import Title from '../components/Title/Title'
import TxtImg from '../components/TxtImg/TxtImg'
import Testimonial from '../components/Testimonial/Testimonial'
import * as AboutStyles from './about.module.css'

function About({ data }) {
    return (
        <Layout>
            <Helmet>
                <title>About | Brixworth Osteopathic Clinic</title>
            </Helmet>
            <section className={AboutStyles.container}>
                <Title>
                    <h1>Your Partner in Restoring Health and Maximising Wellbeing</h1>
                    <h2>Brixworth Osteopathic Clinic focuses on individual needs and circumstances to provide the best possible tailored treatment plans.</h2>
                </Title>
            </section>
            <section className="condense">
                <TxtImg split="half" imgFirst={false} imgSrc={data.clinic} imgAlt="Interior of the clinic">
                    <Underline>
                        <h2>The Practice</h2>
                    </Underline>
                    <p>Brixworth Osteopathic Clinic offers excellent osteopathic care for patients of all ages who may be suffering from a range of issues arising from the musculo-skeletal system. As highly trained primary care practitioners we use refined diagnostic skills and experience to treat the cause not just the symptoms of the problem. We spend the time needed to understand you, the individual, to develop a tailored well rounded rehabilitation programme, giving education and exercises as part of the treatment process.</p>
                    <p>Based in the semi-rural village of Lamport, just off the A508 north of Northampton, the clinic is set within Lamport Manor Farm. Offering a quiet retreat in the relaxing surroundings of a former coach house, the clinic continues to grow both in size and reputation having helped thousands of patients find health again.</p>
                    <p style={{borderTop: '3px solid var(--secondary-500)', borderBottom: '3px solid var(--secondary-500)', color: 'var(--primary-800)', fontStyle: 'italic', padding: 'var(--space-4)', margin: 'var(--space-6) 0', fontSize: 'var(--type-s)', fontWeight: 600}}>"We have seen thousands of patients over the years with over 80% of our new patients now coming to us through word of mouth referrals directly, or through our excellent links we have with local GPs and other health and fitness specialists. We are very proud of the reputation we have”</p>                
                    <p>Our reputation has attracted a variety of other healthcare practitioners who have chosen Brixworth Osteopathic Clinic as their home. Our associates have included McTimmoney Chiropractic, Pilates, Exercise Therapy, Clinical Psychology, and Podiatry.</p>
                </TxtImg>
            </section>
            <section className="condense">
                <Text>
                    <Underline>
                        <h2>Our Services</h2>
                    </Underline>
                    <p>Brixworth Osteopathic Clinic offers care for patients of all ages who may be suffering from a range of health issues.  As highly trained primary care practitioners we use refined diagnostic skills and experience to treat the cause not just the symptoms of the problem.</p>
                </Text>
                <ThreeCard
                    card1ImageSrc={data.osteopathy}
                    card1ImageAlt="Osteopathy treatment"
                    card1Service="Osteopathy"
                    card1Description="Osteopathy is a method of assessing, treating and preventing a wide range of health problems."
                    card1To="/services/osteopathy/"
                    card2ImageSrc={data.massage}
                    card2ImageAlt="Massage treatment"
                    card2Service="Sports & Remedial Massage"
                    card2Description="Sports, Remedial or Deep Tissue massage can provide many benefits - not only if you're a sports person."
                    card2To="/services/sports-remedial-massage/"
                    card3ImageSrc={data.cranial}
                    card3ImageAlt="Cranial Osteopathy treatment"
                    card3Service="Cranial Osteopathy"
                    card3Description="Cranial Osteopathy is a subtle and refined approach to osteopathy that uses very gentle techniques."
                    card3To="/services/cranial-osteopathy/"
                    card4ImageSrc={data.feeding}
                    card4ImageAlt="Infant feeding coaching"
                    card4Service="Infant Feeding Coaching"
                    card4Description="Supporting the feeding mother and baby to find the feeding experience they are both looking for."
                    card4To="/services/infant-feeding-coaching/"
                />
            </section>
            <section id="osteopaths">
                <Text>
                    <Underline>
                        <h2>Meet the Osteopaths</h2>
                    </Underline>
                    <p>The team of osteopaths here at the Brixworth Osteopathic Clinic are highly trained and are focused on helping you find and treat the cause of your symptoms rather than just focusing on the symptoms themselves.</p>
                </Text>
                <Team />
            </section>
            <Testimonial name="Chloe" age="36">
                <p>I'm not sure I could have coped with the [pelvic] pain I experienced during and my first pregnancy when I fell pregnant again. My Osteopath has helped me so much to make the whole experience unrecognisable this time around.</p>
            </Testimonial>
            <section>
                <Appointments fees="all" />
            </section>
        </Layout>
    )
}

export const query = graphql`
    query {
        clinic: file(relativePath: {eq: "practice-portrait.jpg"}) {
            childImageSharp {
                gatsbyImageData(
                    width: 800
                    placeholder: BLURRED
                )
            }
        }
        osteopathy: file(relativePath: {eq: "osteopathy.jpg"}) {
            childImageSharp {
                gatsbyImageData(
                    width: 800
                    height: 400
                    placeholder: BLURRED
                )
            }
        }
        massage: file(relativePath: {eq: "massage.jpg"}) {
            childImageSharp {
                gatsbyImageData(
                    width: 800
                    height: 400
                    placeholder: BLURRED
                )
            }
        }
        cranial: file(relativePath: {eq: "cranial.jpg"}) {
            childImageSharp {
                gatsbyImageData(
                    width: 800
                    height: 400
                    placeholder: BLURRED
                )
            }
        }
        feeding: file(relativePath: {eq: "infant-feeding.jpg"}) {
            childImageSharp {
                gatsbyImageData(
                    width: 800
                    height: 400
                    placeholder: BLURRED
                )
            }
        }
    }
`

export default About